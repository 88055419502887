$env-variable: 'prod';
.pendingContainer {
  z-index: 2;
  display: flex;
  height: 100%;
  background: #0a0b10;
  align-items: center;
  justify-content: center;
  position: fixed;
  user-select: unset;
  top: 0;
  width: 100%;
}
