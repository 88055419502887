$env-variable: 'prod';
@use '@common/style/var' as var;

.iconContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  filter: var(--highlightImgColorDefault);

  &:hover {
    filter: var(--highlightImgColorActive);
  }

  &:active {
    filter: var(--highlightImgColorHover);
  }
}
