$env-variable: 'prod';
@use '@common/style/var' as var;

.checkboxContainer {
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-size: 16px;

  .labelText {
    cursor: pointer;
  }

  .checkboxButton {
    display: block;
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;
    user-select: none;

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      border-radius: 5px;
      background-color: var(--inputTextDefaultBgColor);

      &:hover {
        background-color: var(--inputTextHoverBgColor);
      }

      &:after {
        left: 8px;
        top: 3px;
        width: 6px;
        height: 11px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        content: '';
        position: absolute;
        display: none;
      }
    }

    .checkboxInput {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      outline: 0;
      border: 0;

      &:checked {
        ~ .checkmark {
          opacity: 1;
          background-color: var(--inputTextHoverBgColor);

          &:after {
            display: block;
          }
        }
      }
    }
  }
}
