$env-variable: 'prod';
@use '@common/style/var' as var;

.sideMenuContainer {
  background-color: var(--nightShade);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  z-index: 10;
  align-items: center;
  height: 100%;

  .menuItemContainer {
    box-sizing: border-box;
    padding: 16px 8px 12px;
    color: var(--white);
    display: flex;
    flex-direction: column;
    font-size: 12px;
    row-gap: 4px;
    align-items: center;
    width: 100%;
    user-select: none;
    opacity: 0.5;
    border-left: 4px solid transparent;

    span {
      line-height: 16px;
    }

    &:hover {
      background-color: var(--mainBackgroundColor);
      opacity: 0.8;
    }

    &:active {
      opacity: 1;
      background-color: var(--mainBackgroundColor);
      border-left: 4px solid var(--highlightColorDefault);
      color: var(--highlightColorDefault);

      img {
        filter: var(--highlightImgColorDefault);
      }
    }

    &.active {
      opacity: 1;
      background-color: var(--mainBackgroundColor);
      border-left: 4px solid var(--highlightColorDefault);
      color: var(--highlightColorDefault);

      img {
        filter: var(--highlightImgColorDefault);
      }
    }
  }

  .link {
    width: 100%;
  }

  .logoContainer {
    opacity: 0.5;
    padding: 21px 34px 12px;
    width: 100%;
    border-left: 4px solid transparent;
    cursor: pointer;

    &:active {
      opacity: 1;
      background-color: var(--mainBackgroundColor);
      border-left: 4px solid var(--highlightColorDefault);
    }

    &:hover {
      background-color: var(--mainBackgroundColor);
      opacity: 0.8;
    }
  }

  .versionApplicationContainer {
    color: var(--white);
    font-size: 12px;
    opacity: 0.5;
    margin-top: auto;
    margin-bottom: 16px;

    .versionApplication {
      text-align: center;
      margin-bottom: 5px;
    }
  }
}
