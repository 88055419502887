$env-variable: 'prod';
.landingPageContainer {
  background-color: #0a0b10;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;

  .backgroundImg {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    background-color: #0a0b10;
  }
}

.footerContainer {
  z-index: 1;
  align-self: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #ffffff80;
  justify-self: normal;
  font-size: 14px;
  grid-row-start: 3;

  nav {
    display: flex;
    flex-wrap: wrap;
    row-gap: 12px;
    justify-content: space-around;
    column-gap: 32px;
  }

  a {
    color: #ffffff80;
    text-decoration: none;
  }
}

.landingPage {
  row-gap: 20px;
  width: 100%;
  position: relative;
  display: grid;
  justify-items: center;
  padding: 40px 80px;
  box-sizing: border-box;
  max-width: 1280px;
  grid-template-rows: auto 1fr;

  @media only screen and (max-width: 768px) {
    padding: 24px 16px;
    grid-template-rows: auto auto 1fr;

    svg {
      height: 24px;
    }

    .footerContainer {
      justify-self: unset;
      font-size: 14px;

      nav {
        display: none;
      }
    }
  }
}
