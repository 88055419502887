$env-variable: 'prod';
@use '@common/style/var.scss' as var;

.emailContainer {
  display: flex;
  align-items: center;
  color: var(--highlightColorDefault);
  font-weight: 600;
  cursor: pointer;
  column-gap: 10px;
  position: relative;
  line-height: 24px;
  min-width: 150px;

  &:hover {
    color: var(--highlightColorHover);
  }

  &:active {
    color: var(--highlightColorActive);
  }
}

.roleContainer {
  margin-right: 10px;
  font-weight: 600;
  opacity: 0.6;
  font-size: 12px;
  color: var(--highlightColorDefault);
  letter-spacing: 0.01em;
}

.headerContainer {
  position: fixed;
  height: 64px;
  top: 0;
  background-color: var(--mainBackgroundColor);
  width: 100%;
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
  z-index: 9;

  .infoContainer {
    display: flex;
    column-gap: 48px;
    align-items: center;
  }

  .documentationContainer {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-weight: 500;

    a {
      color: var(--white)_80;

      &:hover {
        color: var(--white);
      }
    }
  }

  .envsContainer {
    display: flex;
    column-gap: 28px;

    .envContainer {
      font-size: 16px;
      font-weight: 600;

      a {
        color: var(--white)_50;
        &:hover {
          color: var(--white);
        }
      }
    }

    .selectedEnv {
      border-bottom: 2px solid var(--highlightColorDefault);
      padding-bottom: 1px;

      a {
        color: var(--white);
      }
    }
  }
}
