$env-variable: 'prod';
@use '@common/style/var' as var;

.formContainer {
  display: grid;
  row-gap: 20px;
}

.mfaButtons {
  display: flex;
  column-gap: 24px;
}

.qrCodeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

%notificationContainer {
  color: var(--white)_50;
  text-align: center;
  line-height: 24px;
  font-size: 14px;

  a {
    color: var(--highlightColorDefault);
    text-decoration: none;
  }
}

.fieldContainer {
  width: 100%;
  position: relative;
  display: flex;
}

.buttonContainer {
  display: grid;
  justify-items: center;
  row-gap: 24px;
  height: 100%;
  align-items: flex-end;
  width: 100%;
}

.inputContainer {
  width: 100%;

  .input {
    position: relative;
    display: flex;
    box-sizing: border-box;
  }

  .inputName {
    color: #ffffffcc;
    line-height: 16px;
    margin-bottom: 4px;
    letter-spacing: 0.01em;
    font-size: 12px;
  }
}

.buttonResetPassword {
  color: var(--highlightColorDefault);
  cursor: pointer;
  right: 0;
  height: 100%;
  align-items: center;
  display: flex;
}

.title {
  font-size: 40px;
  text-align: center;
  font-weight: 700;
}

.text {
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  color: var(--white)_50;

  span {
    font-weight: 600;
    font-size: 16px;
  }

  .buttonEdit {
    cursor: pointer;
    display: inline;
    font-size: 16px;
    color: var(--highlightColorDefault);
    font-weight: 600;
    margin: 0 8px;

    &:active {
      color: var(--highlightColorActive);
    }

    &:hover {
      fill: var(--highlightColorHover);
    }
  }
}

.titleContainer {
  display: grid;
  justify-content: center;
  margin-bottom: 40px;
  color: var(--white);
  row-gap: 16px;

  span {
    font-weight: 600;
  }
}

.infoContainer {
  display: flex;
  justify-content: center;
  column-gap: 12px;
  margin-bottom: 12px;
  color: var(--highlightColorDefault);
  font-size: 14px;
  align-items: center;

  span {
    color: #ffffff80;
  }

  span[data-type='error'] {
    color: #d40000;
  }

  a {
    text-decoration: none;
    color: var(--highlightColorDefault);
  }

  button {
    font-weight: 400;
    color: var(--highlightColorDefault);
    font-size: 16px;
    cursor: pointer;
    background: transparent;
    border: none;

    :disabled {
      color: #8288ae;
    }
  }
}

.notificationContainer {
  @extend %notificationContainer;
}

.errorContainer {
  @extend %notificationContainer;
  color: #d40000;
}

.inputIcon {
  position: absolute;
  display: flex;
  height: 100%;
  justify-content: end;
  align-items: center;
  right: 12px;
}

.backToSignInButton {
  color: var(--highlightColorDefault);
  cursor: pointer;
  z-index: 1;
  height: max-content;
}

.formCenter {
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  grid-row-end: 3;

  .fieldsContainer {
    display: grid;
    row-gap: 28px;
  }

  .titleSection {
    color: #ffffff;
    font-size: 24px;
    text-align: center;
    margin-bottom: 8px;
    line-height: 32px;
  }

  .signFormContainer {
    width: 376px;

    section {
      display: grid;
      row-gap: 28px;
    }
  }

  .signUpFormContainer {
    width: 560px;

    form {
      display: grid;
      row-gap: 48px;
    }
  }

  .doubleFieldContainer {
    column-gap: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .selectWrapper {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      span {
        font-size: 12px;
      }
    }
  }

  @media only screen and (max-width: 768px), screen and (max-height: 650px) {
    max-width: 300px;
    justify-content: center;
    width: inherit;
    position: unset;
    align-items: flex-start;
    padding: 0;

    .doubleFieldContainer {
      grid-template-columns: 1fr;
      row-gap: 28px;
    }

    .titleContainer {
      row-gap: 8px;
      margin-bottom: 32px;
    }

    .title {
      font-size: 32px;
    }

    .text {
      font-size: 14px;
    }

    .fieldInput {
      font-size: 16px;
      padding: 13px 16px;
    }

    .notificationContainer {
      padding: 0;
      font-size: 14px;
    }

    .inputIcon {
      right: 12px;
    }

    .infoContainer {
      font-size: 14px;
    }

    .buttonSubmit {
      padding: 12px 16px;
    }
  }
}
