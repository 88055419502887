$env-variable: 'prod';
%arrow {
  border-style: solid;
  border-color: inherit;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  cursor: pointer;
  position: relative;
}

.arrowDown {
  @extend %arrow;
  cursor: pointer;
  bottom: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrowUp {
  @extend %arrow;
  cursor: pointer;
  top: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.arrowLeft {
  @extend %arrow;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
