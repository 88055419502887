$env-variable: 'prod';
@use '@common/style/var.scss' as var;

.extraButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}

.poolHeader {
  display: flex;
  align-items: center;
  row-gap: 8px;
  column-gap: 8px;

  .item {
    color: var(--white)_80;
  }
}

.nameLink {
  display: flex;
  align-items: center;
  color: var(--highlightColorDefault);
  column-gap: 6px;
  margin-bottom: 12px;
  font-weight: 700;

  &:hover {
    color: var(--highlightColorHover);

    path {
      fill: var(--highlightColorHover);
    }
  }

  &:active {
    color: var(--highlightColorActive);

    path {
      fill: var(--highlightColorActive);
    }
  }
}

.backPageContainer {
  display: flex;
  width: 300px;
  align-items: center;
  column-gap: 14px;
  cursor: pointer;

  .namePool {
    font-size: 18px;
    font-weight: 600;
    color: var(--white);
  }
}

.bigModalContainer {
  min-width: 540px;
}

.permissionContainer {
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 8px;
  align-items: center;

  span {
    visibility: hidden;
    position: absolute;
  }
}
