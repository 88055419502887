$env-variable: 'prod';
@use '@common/style/var.scss' as var;

.successNotificationContainer {
  bottom: 10%;
  position: fixed;
  opacity: 1;
  visibility: visible;
  transition:
    visibility 1s linear,
    opacity 1s linear;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}

.successNotification {
  line-height: 24px;
  color: var(--white)_50;
  background-color: var(--notificationErrorBackground);
  border-radius: 12px;
  display: flex;
  width: 376px;
  overflow: hidden;

  .iconContainer {
    background-color: var(--notificationErrorIconBackground);
    padding: 0 18px;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .textContainer {
    padding: 12px 16px;
  }
}

.hide {
  visibility: hidden;
  opacity: 0;
}
