$env-variable: 'prod';
@use '@common/style/var' as var;

.tooltipWrapper {
  position: relative;
  padding: 8px 12px 8px 0;

  &:hover {
    .tooltip {
      visibility: visible;
    }
  }
}

.tooltip {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  width: 100%;
}

.arrow {
  position: absolute;
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
}

.content {
  background-color: var(--tooltipBackground);
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--white)_80;
  padding: 12px;
  word-break: break-word;
  max-height: 300px;
  overflow-y: auto;
  white-space: break-spaces;
}

.right {
  top: 0;
  left: 100%;
  padding-left: 11px;

  .arrow {
    top: 12px;
    left: 0;
    border-width: 10px 11px 10px 0;
    border-right-color: var(--tooltipBackground);
  }
}

.left {
  top: 0;
  right: 100%;
  padding-right: 11px;

  .arrow {
    top: 12px;
    right: 0;
    border-width: 10px 0 10px 11px;
    border-left-color: var(--tooltipBackground);
  }
}

.top {
  left: 0;
  bottom: 100%;
  padding-bottom: 11px;

  .arrow {
    bottom: 0;
    left: 25%;
    border-width: 11px 10px 0;
    border-top-color: var(--tooltipBackground);
  }
}

.bottom {
  left: 0;
  top: 100%;
  padding-top: 11px;
  font-family: monospace;

  .arrow {
    top: 0;
    left: 25%;
    border-width: 0 10px 11px;
    border-bottom-color: var(--tooltipBackground);
  }
}
