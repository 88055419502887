$env-variable: 'prod';
@use '@common/style/var' as var;

.contentContainer {
  overflow-x: auto;
  padding: 100px 44px 24px;
  font-size: 18px;

  .buttonContainer {
    width: 172px;
  }

  .titleContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    row-gap: 12px;

    .arrowContainer {
      display: flex;
      font-weight: 700;
      cursor: pointer;
      color: var(--highlightColorDefault);
      align-items: center;
      column-gap: 4px;
    }

    .title {
      font-weight: 700;
      font-size: 40px;
    }
  }

  .tabsContainer {
    display: flex;
    margin-top: 25px;
    column-gap: 32px;
    user-select: none;
  }

  .formContainer {
    margin-top: 32px;

    form {
      display: flex;
      flex-direction: column;
      row-gap: 44px;
    }

    .titleSection {
      position: relative;
      color: #ffffff;
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
    }

    .fieldsContainer {
      display: grid;
      row-gap: 28px;
      grid-template-columns: 190px auto;
      align-items: center;
      width: 100%;

      span {
        font-size: 16px;
        font-weight: 600;
      }
    }

    section {
      display: grid;
      row-gap: 28px;
      max-width: 700px;
    }
  }
}

.notificationContainer {
  right: -350px;
  display: flex;
  position: absolute;
  color: var(--green);
  letter-spacing: 2px;
  font-size: 12px;
  text-transform: uppercase;
  width: 310px;
  column-gap: 14px;
}
