$env-variable: 'prod';
@use '@common/style/var' as var;

.mainLogoContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logoContainer {
  display: flex;
  column-gap: 70px;
}

.projectIconContainer {
  display: flex;
  align-items: center;
  overflow-wrap: break-word;
  min-width: 120px;
  max-width: 190px;
  column-gap: 4px;
  color: var(--white)_50;
  cursor: pointer;

  .chevronContainer {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    color: var(--white);
  }

  .projectName {
    display: flex;
    column-gap: 12px;
    align-items: center;
  }
}
