$env-variable: 'prod';
@use '@common/style/var.scss' as var;

%buttonCancel {
  height: 45px;
  user-select: none;
  letter-spacing: 0.02em;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  color: var(--white)_80;
  border-radius: 8px;
  border: 0;
  padding: 15px 24px;
  white-space: nowrap;

  &:hover {
    background-color: var(--ghostButtonHover);

    path {
      fill: var(--highlightColorHover);
    }
  }

  &:active {
    background-color: var(--ghostButtonActive);

    path {
      fill: var(--highlightColorActive);
    }
  }

  &:disabled {
    color: var(--white);
    background-color: var(--ghostButtonDisabled);
    opacity: 0.2;
  }
}

%buttonSubmit {
  display: block;
  background: var(--highlightColorDefault);
  color: var(--white);
  font-weight: 400;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  transition: border 0.5s;
  border: 0;
  white-space: normal;
  line-height: 120%;
  width: 100%;
  padding: 12px 24px;
  font-size: 16px;

  &:hover {
    background: var(--highlightColorHover);
  }

  &:active {
    background: var(--highlightColorActive);
  }

  &:disabled {
    color: var(--white);
    background-color: var(--ghostButtonDisabled);
    opacity: 0.2;
  }
}

.buttonAdd {
  @extend %buttonCancel;
  align-items: center;
  display: flex;
  column-gap: 8px;
  margin-top: auto;
}

.buttonDownload {
  @extend %buttonCancel;
  align-items: center;
  display: flex;
  column-gap: 8px;

  &:before {
    content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.6'%3E%3Cpath d='M19.5 19.0385C19.5 19.2933 19.2901 19.4998 19.0312 19.4998H4.96875C4.70987 19.4998 4.5 19.2933 4.5 19.0385V17.6545C4.5 17.3997 4.70987 17.1931 4.96875 17.1931H19.0312C19.2901 17.1931 19.5 17.3997 19.5 17.6545V19.0385Z' fill='%232E8DE5'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.0422 12.3639L15.8224 10.2141C16.0958 10.0027 16.4893 10.0378 16.7195 10.2942L17.1727 10.7989C17.4226 11.0771 17.3832 11.505 17.0865 11.7345L12.4137 15.3478C12.1757 15.5318 11.8408 15.5318 11.6028 15.3478L6.93002 11.7345C6.6333 11.505 6.59384 11.0771 6.84375 10.7989L7.29695 10.2942C7.52721 10.0378 7.9207 10.0027 8.1941 10.2141L10.9743 12.3639L10.9743 4.31238C10.9743 3.95567 11.2681 3.6665 11.6305 3.6665L12.386 3.6665C12.7484 3.6665 13.0422 3.95567 13.0422 4.31238V12.3639Z' fill='%232E8DE5'/%3E%3C/g%3E%3C/svg%3E%0A");
  }
}

.buttonBack {
  &:before {
    content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.62354 13.1489L11.3033 16.228C11.5648 16.5284 11.5232 16.9862 11.2119 17.2347L10.4637 17.8317C10.1706 18.0655 9.74528 18.0269 9.4991 17.744L4.89995 12.4595C4.67066 12.1961 4.67066 11.8039 4.89995 11.5405L9.4991 6.25595C9.74528 5.9731 10.1706 5.93446 10.4637 6.16834L11.2119 6.76532C11.5232 7.01376 11.5648 7.47156 11.3033 7.77203L8.62354 10.8511H18.8C19.1866 10.8511 19.5 11.1645 19.5 11.5511V12.4489C19.5 12.8355 19.1866 13.1489 18.8 13.1489H8.62354Z' fill='%232E8DE5'/%3E%3C/svg%3E%0A");
  }
}

.buttonSubmit {
  @extend %buttonSubmit;
}

.buttonReset {
  @extend %buttonSubmit;

  align-items: center;
  display: flex;
  column-gap: 8px;

  &:before {
    line-height: 1%;
    content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.01603 15.9828C3.76412 16.235 3.33301 16.0566 3.33301 15.7001V10.8333H8.20129C8.55756 10.8333 8.73607 11.264 8.48427 11.516L6.48551 13.5167C7.41047 14.4625 8.67676 14.997 9.99967 15C12.1158 14.9968 14.0009 13.6618 14.7063 11.6667H14.7213C14.7513 11.5815 14.779 11.4955 14.8044 11.4089C14.9004 11.0814 15.1869 10.8333 15.5282 10.8333H15.8201C16.2414 10.8333 16.57 11.2046 16.4684 11.6135C15.7388 14.5492 13.0915 16.6666 9.99967 16.6667H9.99134C8.22364 16.6719 6.52731 15.9697 5.28051 14.7167L4.01603 15.9828ZM5.19467 8.58954C5.09767 8.91755 4.81071 9.16666 4.46865 9.16666H4.1784C3.75708 9.16666 3.4285 8.79537 3.53012 8.38649C4.25946 5.452 6.9049 3.33496 9.99551 3.33329H9.99967C11.7677 3.32763 13.4644 4.02985 14.7113 5.28332L15.9839 4.01404C16.236 3.76254 16.6663 3.94112 16.6663 4.29724V9.16666H11.7991C11.4427 9.16666 11.2643 8.73568 11.5164 8.48373L13.518 6.48332C12.5921 5.53636 11.3241 5.00177 9.99967 4.99999C7.88347 5.00311 5.99841 6.33814 5.29301 8.33332H5.27801C5.24794 8.41799 5.22014 8.50342 5.19467 8.58954Z' fill='white' fill-opacity='0.2'/%3E%3C/svg%3E%0A");
  }
}

.buttonCancel {
  @extend %buttonCancel;
}

.inlineButtonCancel {
  @extend %buttonCancel;
  display: inline;
  margin-left: 20px;
}

.singleButtonContainer {
  width: 172px;
}

.groupButtonContainer {
  display: flex;
  column-gap: 8px;
}
