$env-variable: 'prod';
@use './var.scss' as var;

.breadcrumbs {
  border: 1px solid var(--tableColor);
  border-radius: 8px;
  display: inline-flex;
  overflow: hidden;

  &__item {
    font-size: 14px;
    color: var(--white)_50;
    outline: none;
    padding: 8px 24px;
    position: relative;
    text-decoration: none;
    transition: background 0.2s linear;

    &:hover:after,
    &:hover {
      background-color: var(--tableColor);
    }

    &:focus:after,
    &:focus,
    &.isActive:focus {
      color: var(--white);
      background-color: var(--tableColor);
    }

    &:after,
    &:before {
      background: var(--mainBackgroundColor);
      bottom: 0;
      clip-path: polygon(50% 50%, -50% -50%, 0 100%);
      content: '';
      left: 100%;
      position: absolute;
      top: 0;
      transition: background 0.2s linear;
      width: 1em;
      z-index: 1;
    }

    &:before {
      background: var(--mainBackgroundColor);
      margin-left: 1px;
    }

    &:last-child {
      border-right: none;
    }

    &.isActive {
      color: var(--white);
      background-color: var(--tableColor);
    }
  }
}
