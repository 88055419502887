$env-variable: 'prod';
.tableHeaderContainer {
  display: flex;
  column-gap: 8px;

  .searchContainer {
    flex: 1;
  }
}

.singleButtonTable {
  display: flex;
  justify-content: end;
}
