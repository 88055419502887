$env-variable: 'prod';
.configParametersPage {
  display: grid;
  row-gap: 22px;
  position: relative;
  margin-left: 24px;
}

.poolConfigData {
  display: grid;
  row-gap: 16px;
  width: 650px;

  .permissionsContainer {
    display: grid;
    row-gap: 12px;
  }
}
