$env-variable: 'prod';
%inputContainer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 47%;
}

.checkboxContainer {
  height: 10px;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-width: 1px;
    border-style: solid;
    border-color: hsla(0, 0%, 89.8%, 0.3);
    border-radius: 2px;

    &::after {
      left: 5px;
      top: 1px;
      width: 6px;
      height: 11px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      content: '';
      position: absolute;
      display: none;
    }
  }
}

.inputContainer {
  @extend %inputContainer;
}

.wrapInputContainer {
  @extend %inputContainer;
  flex-wrap: wrap;
}

.inputName {
  margin-right: 20px;
}

.elementsContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.containerWithIcon {
  position: relative;
  width: 100%;
}

.iconWrapper {
  position: absolute;
  top: 14px;
  right: -29px;

  svg {
    width: 17px;
    opacity: 80%;
  }
}

.selectContainer {
  width: 47%;
  margin-bottom: 30px;
}
